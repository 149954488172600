import React from "react";

type Props = {
  children?: React.ReactNode;
  className?: string;
  type?: "h1" | "h2" | "h3" | "h4" | "title";
};

export const Heading = ({ children, className = "", type = "h1" }: Props) => {
  if (type === "h4") {
    return (
      <h4
        className={`my-5 text-h4 font-montserrat font-semibold text-primary ${className}`}
      >
        {children}
      </h4>
    );
  }

  if (type === "h3") {
    return (
      <h3
        className={`my-5 text-h3 font-montserrat font-semibold text-primary ${className}`}
      >
        {children}
      </h3>
    );
  }

  if (type === "h2") {
    return (
      <h2
        className={`my-5 font-semibold font-montserrat text-h2 text-primary ${className}`}
      >
        {children}
      </h2>
    );
  }

  if (type === "title") {
    return (
      <h2
        className={`my-5 font-semibold font-poppins text-md text-primary ${className}`}
      >
        {children}
      </h2>
    );
  }

  return (
    <h1
      className={`mt-10 mb-5 font-montserrat text-h1 font-semibold text-primary ${className}`}
    >
      {children}
    </h1>
  );
};
