import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Heading, Paragraph } from "~/components";
import PriceBlock from "~/components/PriceBlock";
import { prices } from "../ChardhamHome/prices";

export const Chardham1N2D: React.FunctionComponent = () => (
  <div>
    <StaticImage
      placeholder="blurred"
      className="h-[50vh]"
      src="../../images/bg/1-chardham-carousel.png"
      alt="pilgrimage-banner"
      layout="fullWidth"
    />
    <section id="char-dham-package" className="mb-3">
      <Heading type="h1" className="mb-2">
        Chardham by Helicopter 1N/2D
      </Heading>
      <div className="flex flex-col-reverse md:flex-row">
        <div className="flex-1">
          <Heading type="title" className="mb-0 underline font-light">
            Duration
          </Heading>
          <Heading type="title" className="mt-0">
            1 Night / 2 Days
          </Heading>
          <Heading type="title" className="mb-0 underline font-light">
            Route
          </Heading>
          <Heading type="title" className="mt-0 mb-0">
            Dehradun - Yamunotri - Gangotri - Kedarnath - Badrinath - Dehradun
          </Heading>
        </div>
        <PriceBlock
          discountedPrice={prices.charDham1N2D.discountedPrice}
          price={prices.charDham1N2D.price}
          itineraryLink=""
          packageName="Chardham Yatra 1N/2D package"
          tncLink=""
          priceDetails="Charter of 6 Yatris (early bird offer)"
        />
      </div>
      <div className="flex-1 md:w-[50%] text-justify">
        <Heading type="title" className="mb-0 underline font-light">
          Package Includes
        </Heading>
        <ol className="text-md text-secondary mb-5 leading-relaxed font-poppins list-decimal ml-5">
          <li>
            Pickup from Dehradun airport(or any location within city limits) and
            transit to the helipad.
          </li>
          <li>Travel from Dehradun to each of the Chardhams by Helicopter.</li>
          <li>
            Route: Dehradun - Yamunotri - Gangotri - Kedarnath - Badrinath -
            Dehradun.
          </li>
          <li>Night stay at Gangotri at Harsil Village Resort</li>
          <li>
            Accommodations with all meals included. Specific food requests in
            accordance with Yatris’ request on demand and availability.
          </li>
          <li>
            Dedicated ground staff assistance and priority darshan at all the
            Chardham temples.
          </li>
          <li>Choice of Palki or Pony at Yamunotri Dham.</li>
          <li>
            Shri Kedarnath ji Helicopter shuttle services: Phata Helipad-
            Kedarnath- Phata Helipad
          </li>
          <li>Local Transportation at all the Dhams.</li>
          <li> Inclusive of all taxes, royalty and other charges.</li>
          <li>
            For more details on the yatra check out the itinerary, for more
            personalised options, keep reading and check out the add-ons
            section.
          </li>
        </ol>
      </div>
      <div className="flex-1 md:w-[50%] text-justify">
        <Heading type="title" className="mb-0 underline font-light">
          Package Exclusions
        </Heading>
        <Paragraph className="mb-0">
          Below are the package exclusions which can be arranged on demand at an
          additional charge:
        </Paragraph>
        <ol className="text-md text-secondary leading-relaxed font-poppins ml-5 list-decimal">
          <li>Accommodation at Dehradun with meal requests.</li>
          <li>
            Any additional night stay would include accommodation and additional
            flying charges.(If request made prior, discounted prices could be
            availed)
          </li>
          <li>
            Any local sightseeing or special pooja request options could be
            availed only subject to time constraints or package extensions. (To
            learn about the various tourist destinations read our blog)
          </li>
          <li>Flights (Scheduled/Non-Scheduled) or Train Tickets Booking.</li>
          <li>Mussoorie, Haridwar or Rishikesh Day-trip.</li>
          <li>
            Pickup or/and drop from Mussoorie, Haridwar or Rishikesh to/from
            Dehradun.
          </li>
          <li>And much more.</li>
        </ol>
      </div>
    </section>
  </div>
);
