import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Heading, Paragraph, Slider } from "~/components";
import * as classes from "./styles.module.css";
import { PhotoGallery } from "./PhotoGallery";
import { OurClients } from "./OurClients";
import FAQ from "./FAQ";
import { Blogs } from "./Blogs";
import clsx from "clsx";
import Testimonials from "./Testimonials";
import OtherPackages from "./OtherPackages";
import PriceBlock from "../../components/PriceBlock";
import { prices } from "./prices";

const SliderSection = () => {
  return (
    <Slider
      settings={{
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <></>,
        nextArrow: <></>,
      }}
    >
      <div style={{ width: "100%" }}>
        <div className={clsx("h-[550px]", classes.slideWrapper)}>
          <video
            autoPlay
            loop
            muted
            playsInline
            className="w-full h-full"
            style={{
              objectFit: "cover",
              objectPosition: "top",
            }}
          >
            <source
              src="https://d2wedyshsbbj5x.cloudfront.net/thumby-video.mp4"
              type="video/mp4"
            ></source>
          </video>
        </div>
      </div>
      <div style={{ width: "100%" }}>
        <StaticImage
          placeholder="blurred"
          className={clsx("h-[550px] max-h-[100vh]", classes.slideWrapper)}
          src="../../images/bg/1-chardham-carousel.png"
          alt="pilgrimage-banner"
          layout="fullWidth"
        />
      </div>
      <div style={{ width: "100%" }}>
        <StaticImage
          placeholder="blurred"
          className={clsx("h-[550px] max-h-[100vh]", classes.slideWrapper)}
          src="../../images/blog-1-cover-image.png"
          alt="pilgrimage-banner"
          layout="fullWidth"
        />
      </div>
      <div style={{ width: "100%" }}>
        <StaticImage
          placeholder="blurred"
          className={clsx("h-[550px] max-h-[100vh]", classes.slideWrapper)}
          src="../../images/bg/3-carousal-2.png"
          alt="pilgrimage-banner"
          layout="fullWidth"
        />
      </div>
      <div style={{ width: "100%" }}>
        <StaticImage
          placeholder="blurred"
          className={clsx("h-[550px]", classes.slideWrapper)}
          src="../../images/bg/2-carousal-1.png"
          alt="pilgrimage-banner"
          layout="fullWidth"
        />
      </div>
    </Slider>
  );
};

export const ChardhamHome: React.FunctionComponent = () => (
  <div>
    <div className={clsx("mb-10 -mx-5", classes.heroSectionWrapper)}>
      <SliderSection />
    </div>

    <section>
      <Heading className="mt-0 !text-h2 text-center">
        Chardham Yatra by Helicopter: Bookings open for May-June 2025
      </Heading>
      <Heading type="h3">
        Exclusive 5N 6D Packages for Best Price: Booking Open
      </Heading>
      <Paragraph>
        The sacred Chardham Yatra holds immense value in the belief system,
        spiritual relm, and cultural consciousness of Million’s of Yatris from
        across the Globe. Additionally, the Chardham sites are major pilgrimage
        tourist destinations contributing significantly to the tourism of
        Uttarakhand. But, dear Yatri, it is important for you to note that the
        Chardham Yatra by helicopter is a seasonal phenomenon, operational only
        during 2 seasons of the year. The first season is between May-June and
        the second between Sep-Oct. Post successful operations in the May-June
        season, Thumby Aviation is happy to announce the opening of booking of
        its package(s) to the Chardham Yatra by Helicopter for May - June 2025.
      </Paragraph>
      <Paragraph className="mt-4">
        For more on Chardham Yatra by helicopter, visit our{" "}
        <a href="/chardham-by-helicopter/packages">packages</a>,{" "}
        <a href="/chardham-by-helicopter/">booking page</a>,{" "}
        <a href="?show=contact-us">contact us</a>, read{" "}
        <a href="/blogs">blogs</a>, and{" "}
        <a href="/chardham-by-helicopter/#testimonials">testimonials</a>, or
        visit our{" "}
        <a
          href="https://www.instagram.com/helitaxii/"
          className="underline"
          target="_blank"
          rel="noreferrer noopener"
        >
          social Media
        </a>{" "}
        pages to learn more.
      </Paragraph>
      <Paragraph>
        Checkout all packages: Chardham Yatra by Helicopter,{" "}
        <a href="/chardham-by-helicopter/packages/do-dham">
          Do Dham Yatra by Helicopter
        </a>
        ,{" "}
        <a href="/chardham-by-helicopter/packages/ek-dham">
          Ek Dham Yatra by Helicopter.
        </a>
      </Paragraph>
    </section>

    <section>
      <Heading type="h2">Chardham by Helicopter: About Package</Heading>
      <div className="flex flex-col-reverse md:flex-row">
        <div className="flex-1 md:w-[50%]">
          <Paragraph className="text-justify">
            Your Chardham Yatra by Helicopter will start from Dehradun, where
            you will be picked up on arrival and dropped to hotel Fairfield by
            Marriot where you will enjoy a complimentary night stay with meal
            inclusions. Here, our team will meet all passengers with a promise
            of excellent service and for a detailed briefing to prepare you for
            the Yatra ahead to the divine sites. Find the detailed description
            of the chardham yatra by helicopter package below:
          </Paragraph>
          <Heading type="title" className="mb-0 underline font-light">
            Duration
          </Heading>
          <Heading type="title" className="mt-0">
            5N/6D(Includes complimentary stay at Marriott, Dehradun){" "}
          </Heading>
          <Heading type="title" className="mb-0 underline font-light">
            Route
          </Heading>
          <Heading type="title" className="mt-0 mb-0">
            Dehradun - Yamunotri - Gangotri - Phata - Kedarnath - Phata -
            Badrinath - Dehradun
          </Heading>
        </div>
        <PriceBlock
          price={prices.charDham5N6D.price}
          discountedPrice={prices.charDham5N6D.discountedPrice}
          tncLink="https://d2wedyshsbbj5x.cloudfront.net/2024+Char+Dham+Yatra+5N+6D+T%26C+v3.pdf"
          itineraryLink="https://d2wedyshsbbj5x.cloudfront.net/2024+Char+Dham+Yatra+Itinerary+5N+6D+v3.pdf"
          packageName="Chardham Yatra 5N/6D Package"
          priceDetails="Price per person (Limited period offer)"
        />
      </div>
      <div className="flex-1 md:w-[50%]">
        <Heading type="title" className="mb-0 underline font-light">
          Package Includes
        </Heading>
        <ol className="text-md text-secondary mb-5 leading-relaxed font-poppins list-decimal ml-5 text-justify">
          <li>
            Pickup from Dehradun airport(or any location within city limits) and
            transit to the hotel.
          </li>
          <li>
            Hassle free check-in and lavish stay at hotel Fairfield by Marriot,
            Dehradun.
          </li>
          <li>Detailed briefing for passengers by Customer Success Team</li>

          <li>
            Travel from Dehradun to each of the Chardhams(Yamunotri, Gangotri,
            Kedarnath, Badrinath) by Helicopter(air travel).
          </li>
          <li>
            Route of journey: Dehradun - Yamunotri - Gangotri - (Phata -
            Kedarnath - Phata) - Badrinath - Dehradun, 1 transit per day.
          </li>
          <li>
            Accommodation at all sites with all meals included. Specific food
            requests in accordance with Yatris’ request on demand and
            availability.
          </li>
          <li>
            Best accommodation options for passengers(tentative):
            <ol
              className="text-md text-secondary leading-relaxed font-poppins ml-5"
              style={{
                listStyleType: "lower-alpha",
              }}
            >
              <li>Dehradun: Fairfield by Marriott</li>
              <li>Yamunotri: Shiv Shakti Eco Resort / La Arkja Resort</li>
              <li>Gangotri: Namami Retreat/ Harsil Village Resort</li>
              <li>
                Kedarnath: Turiya Resort / Kedar Paradise / Kedar Glow Resort
              </li>
              <li>Badrinath: Snow Crest / Amritara by Avadh / Hotel Yoga</li>
            </ol>
          </li>
          <li>
            Dedicated ground staff assistance and VIP darshans at all the
            Chardham temples.
          </li>
          <li>
            Local sightseeing with Thumby Aviation guides at all possible
            destinations.(For example: The Pracheen Uttarkashi temple in
            Yamunotri)
          </li>
          <li>Choice of Palki or Pony at Yamunotri Dham.</li>
          <li>
            Shri Kedarnath ji Helicopter shuttle services: Phata Helipad-
            Kedarnath- Phata Helipad
          </li>
          <li>Evening aarti in Badrinath temple darshan.</li>
          <li>Mana Village visit at Badrinath.</li>
          <li>Local Transportation at all the Dhams.</li>
          <li>Inclusive of all taxes and other charges.</li>
          <li>
            For more details on the yatra check out the itinerary, for more
            personalised options, keep reading and check out the add-on’s
            section.
          </li>
        </ol>
      </div>
      <div className="flex-1 md:w-[50%]">
        <Heading type="title" className="mb-0 underline font-light">
          Package Exclusions
        </Heading>
        <Paragraph className="mb-0">
          Below are the package exclusions which can be arranged on demand at an
          additional charge:
        </Paragraph>
        <ol className="text-md text-secondary leading-relaxed font-poppins ml-5 list-decimal text-justify">
          <li>
            Rudra Abhishek Puja at Kedarnath (INR 6,500 for 05 People approx.)
          </li>
          <li>Voluntary stay at Kedarnath top.</li>
          <li>
            Maha Abhishek Puja at Badrinath (Maha Abhishek Puja: INR
            4,300/Person (approx.))
          </li>
          <li>Any special pooja in Yamunotri/Gangotri.</li>
          <li>Flights (Scheduled/Non-Scheduled) or Train Tickets Booking.</li>
          <li>Mussoorie, Haridwar or Rishikesh Day-trip.</li>
          <li>
            Triyuginarayan Temple at Kedarnath Dham(where Lord Shiva and Ma
            Parvati tied the knot)
          </li>
          <li>
            Pickup or/and drop from Mussoorie, Haridwar or Rishikesh to/from
            Dehradun(or from any other part of Uttarakhand close to Dehradun).
          </li>
          <li>Pooja items in a religious tour.</li>
        </ol>
      </div>
    </section>
    <OtherPackages />
    <Testimonials />
    <Blogs />
    <PhotoGallery />
    <OurClients />
    <FAQ />
  </div>
);
