import React from "react";

import { Heading, Paragraph, Slider } from "~/components";
import { Charter, charters } from "./charters";

type CardProps = Charter;

const Card = ({ Image, title, description, name }: CardProps) => (
  <div className="bg-lblue rounded-3xl flex flex-col clg:flex-row clg:mr-10">
    <Image />

    <div className="py-6 px-5 clg:px-14 w-full clg:w-[450px] h-full text-primary-dark font-montserrat">
      <span className="block font-bold text-2xl clg:text-[35px]">{name}</span>
      <span className="block text-sm clg:text-h4 font-semibold mt-3">
        {title}
      </span>
      <Paragraph
        font="montserrat"
        className="mt-3 opacity-60 text-[13px] mb-0 text-justify"
        _dangerouslySetInnerHTML={description}
      ></Paragraph>
    </div>
  </div>
);

export const FleetForYourCharter = () => (
  <div className={` mt-16 mb-20`} id="our-fleet">
    <Heading className="mb-14 text-center" type="h2">
      Fleet for your Charter
    </Heading>

    <Slider
      settings={{
        slidesToScroll: 1,
        slidesToShow: 1,
        infinite: true,
        speed: 500,
        variableWidth: true,
        responsive: [
          {
            breakpoint: 864,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              speed: 500,
              variableWidth: false,
            },
          },
        ],
      }}
    >
      {charters.map(({ name, title, description, Image }) => (
        <Card
          key={name}
          name={name}
          title={title}
          description={description}
          Image={Image}
        />
      ))}
    </Slider>
  </div>
);
