import React from "react";
import { LeftSection } from "./LeftSection";
import * as classes from "./styles.module.css";
import clsx from "clsx";
import { StaticImage } from "gatsby-plugin-image";
import { Slider } from "~/components";

export const HeroSection: React.FC = () => {
  return (
    <>
      <div className="mt-6 mb-4">
        <LeftSection />
      </div>

      <div className={clsx("-mx-5", classes.heroSectionWrapper)}>
        <Slider
          settings={{
            slidesToScroll: 1,
            slidesToShow: 1,
            autoplay: true,
            infinite: true,
            dots: true,
            autoplaySpeed: 4000,
            speed: 1000,
            prevArrow: <></>,
            nextArrow: <></>,
          }}
        >
          <StaticImage
            placeholder="blurred"
            className={clsx("h-[550px]", classes.slideWrapper)}
            src="../../../images/bg/1-chardham-carousel.png"
            alt="pilgrimage-banner"
            layout="fullWidth"
          />
          <StaticImage
            placeholder="blurred"
            className={clsx("h-[550px]", classes.slideWrapper)}
            src="../../../images/bg/2-carousal-1.png"
            alt="pilgrimage-banner"
            layout="fullWidth"
          />
          <StaticImage
            placeholder="blurred"
            className={clsx("h-[550px]", classes.slideWrapper)}
            src="../../../images/bg/3-carousal-2.png"
            alt="pilgrimage-banner"
            layout="fullWidth"
          />
          <StaticImage
            placeholder="blurred"
            className={clsx("h-[550px]", classes.slideWrapper)}
            src="../../../images/bg/4-carousel.jpeg"
            alt="pilgrimage-banner"
            layout="fullWidth"
            objectPosition="center"
          />
          <StaticImage
            placeholder="blurred"
            className={clsx("h-[550px]", classes.slideWrapper)}
            src="../../../images/bg/5-carousel.jpeg"
            alt="pilgrimage-banner"
            layout="fullWidth"
            objectPosition="bottom"
          />
          <StaticImage
            placeholder="blurred"
            className={clsx("h-[550px]", classes.slideWrapper)}
            src="../../../images/bg/6-carousel.jpeg"
            alt="pilgrimage-banner"
            layout="fullWidth"
          />
          <StaticImage
            placeholder="blurred"
            className={clsx("h-[550px]", classes.slideWrapper)}
            src="../../../images/bg/7-carousel.jpeg"
            alt="pilgrimage-banner"
            layout="fullWidth"
          />
          <StaticImage
            placeholder="blurred"
            className={clsx("h-[550px]", classes.slideWrapper)}
            src="../../../images/bg/8-carousel.jpeg"
            alt="pilgrimage-banner"
            layout="fullWidth"
          />
        </Slider>
      </div>
    </>
  );
};
