import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export type Charter = {
  name: string;
  title: string;
  description: string;
  Image: () => JSX.Element;
};

export const charters: Charter[] = [
  {
    name: "Bell 412",
    title: "Twin Engine Helicopter",
    description:
      "The Bell 412, a workhorse in the Huey family, features a composite four-blade rotor, enhancing its performance and stability. This twin-engine utility helicopter excels in diverse roles, including troop and cargo transport, search and rescue, and medical evacuation, thanks to its spacious cabin and impressive 5,000-pound lifting capacity. Whether navigating challenging terrains or soaring over vast distances, the Bell 412 consistently proves its mettle as a reliable and versatile aviation asset. <b>The newest addition to our fleet, VT-GRD is a Bell 412.</b>",
    Image: () => (
      <StaticImage
        className="rounded-3xl w-full clg:w-[350px] clg:h-[426px] h-[300px]"
        src="../../../images/VT-GRD-Bel-412-2.jpg"
        alt="charter-bell-407-03"
      />
    ),
  },
  {
    name: "Airbus H125(B3)",
    title: "Single Engine Powerhouse",
    description:
      "The Airbus H125(B3), formerly known as the AS350 B3e, is a single-engine powerhouse, renowned for its agility and resilience. This three-bladed helicopter excels in high-altitude and hot environments, making it ideal for tasks such as mountain rescue, firefighting, and aerial tourism. Its compact size and maneuverability enable precise operations in tight spaces. The comfortable cabin can seat up to six passengers or carry essential cargo, catering to a diverse range of missions. Whether operating in demanding conditions or performing delicate maneuvers, the H125(B3) consistently proves its worth as a versatile and capable aircraft.",
    Image: () => (
      <StaticImage
        className="rounded-3xl h-[300px] w-full clg:w-[350px] clg:h-[426px]"
        src="../../../images/airbus-h125.jpeg"
        alt="charter-bell-407-03"
      />
    ),
  },
  {
    name: "Bell 407",
    title: "Light Single Engine Helicopter",
    description:
      "Light utility helicopters are perfect for both intra-city and inter-city travel. This four-blade, single-engine helicopter features a seating configuration for seven, including the pilot and passengers, with room for five passengers in the main cabin. The Bell 407GX helicopter is powered by Rolls-Royce's FADEC-controlled 250-C47B turbine engine, generating 813 shaft horsepower (shp). This engine enables the aircraft to reach a maximum cruising speed of 246 km/h and a range of up to 611 km. The helicopter also boasts a service ceiling of 5,698 m. Our Bell 407 is utilized for a variety of purposes, including corporate and pilgrimage charters, air medical services, aerial surveys, support for political parties, and movie filming.",
    Image: () => (
      <StaticImage
        className="rounded-3xl h-[300px] w-full clg:w-[350px] clg:h-[426px]"
        src="../../../images/Charters 04@2x.png"
        alt="charter-bell-407-04"
      />
    ),
  },
];
